<template>
	<div>
		<img class="banner" style="width: 100%;" src="../assets/banner.png" alt="">
		<!-- 留言 -->
		<div class="formbox">
			<div class="title">投稿</div>
			<div class="titles">CONTRIBUTE</div>
			<div class="fcs">
				<input v-model="form.name" placeholder="姓名" maxlength="5"></el-input>
				<input v-model="form.phone" placeholder="联系方式" maxlength="15"></el-input>
			</div>
			<div class="titless">上传封面图</div>
			<div style="height: 20px;"></div>
			<el-upload
				action="https://www.idongdian.com/api/common/upload"
				list-type="picture-card"
				:on-success="uploadSuccess"
			  	:on-remove="handleRemove"
				:on-preview="handlePictureCardPreview"
				:limit="1"
				:headers='header'
				>
				<i class="el-icon-plus"></i>
				</el-upload>
				<el-dialog :visible.sync="dialogVisible">
				<img width="100%" :src="dialogImageUrl" alt="">
			</el-dialog>
			<div class="titless">投稿图片</div>
			<div style="height: 20px;"></div>
			<el-upload
				action="https://www.idongdian.com/api/common/upload"
				list-type="picture-card"
				:on-success="uploadSuccess1"
				:on-preview="handlePictureCardPreview1"
				:on-remove="handleRemove1"
				:headers='header'
				>
				<i class="el-icon-plus"></i>
				</el-upload>
				<el-dialog :visible.sync="dialogVisible">
				<img width="100%" :src="dialogImageUrl" alt="">
			</el-dialog>
			<div class="titless">投稿标题</div>
			<textarea v-model="form.title" placeholder="请输入" style="height: 100px;"></textarea>
			<div class="titless">投稿内容</div>
			<textarea v-model="form.contribute" placeholder="请输入"></textarea>
			<div class="btnbox fcs">
				<div class="btn1 fcc" @click="define">提交</div>
				<div class="btn2 fcc" @click="chongzhi">重置</div>
			</div>
		</div>
		<div style="height: 80px;"></div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
			
				dialogImageUrl: '',
				dialogVisible: false,
				disabled: false,
				form: {
					name: '',
					phone: '',
					title: '',
					contribute:'',
					image:[],
					images:[]
				},
				info: {},
				
				header:{
					'token': window.localStorage.getItem('tokrn')
					// 'Content-Type':'multipart/form-data; boundary=----WebKitFormBoundarys8zHWZ1qhkaGTZYJ',
					
				}

			}
		},
		created() {
			this.getinfo();
		},
		methods: {
			handleRemove(file, fileList ) {
				console.log(file, fileList );
				this.form.image=[]
				fileList.forEach(i=>{
					this.form.image.push(i.response.data.fullurl)
				})
				console.log(this.form);
			},
				handleRemove1(file, fileList ) {
				console.log(file, fileList );
				this.form.images=[]
				fileList.forEach(i=>{
					this.form.images.push(i.response.data.fullurl)
				})
				console.log(this.form);
			},
			handlePictureCardPreview(file) {
				this.dialogImageUrl = file.url;
				this.dialogVisible = true;
			},
			handlePictureCardPreview1(file) {
				this.dialogImageUrl = file.url;
				this.dialogVisible = true;
			},
			uploadSuccess(obj,res,file) {
			console.log(obj,888888,res,666,file);
				
			if (obj.code=1) {
				// this.$message.success({dangerouslyUseHTMLString: true,
				// message: obj.msg})
				// this.inputForm.url = obj.url // 后端返给我们的路径
				console.log(obj.data.fullurl);
				this.form.image.push(obj.data.fullurl)
				console.log(this.form);
			
				
			} else {
				this.$message.error('操作失败')
			}
			},
			remove2(obj,fileList){
				console.log(obj,fileList);
			},
			uploadSuccess1(obj,res,file) {
			console.log(obj,888888,res,666,file);
			if (obj.code=1) {
				
				this.form.images.push(obj.data.fullurl)
			console.log(this.form);
				
			} else {
				this.$message.error('操作失败')
			}
			},
			
		
			handleDownload(file) {
				console.log(file);
			},
			//提交
			define() {
				
				// this.form.image.JSON(',')
				console.log(this.form.images.join(','),122121212);
				// console.log(JSON.stringify(this.form.image));
				// console.log(JSON.stringify(this.form.images));
				if(this.form.name == ''){
					this.$message({
								type: 'error',
								offset: 80,
								message: '请填写名字'
							});
						return
				}
				if(this.form.phone == ''){
					this.$message({
								type: 'error',
								offset: 80,
								message: '请填写手机号'
							});
							return
				}
				if(this.form.title == ''){
					this.$message({
								type: 'error',
								offset: 80,
								message: '请填写标题'
							});
							return
				}
				if(this.form.contribute == ''){
					this.$message({
								type: 'error',
								offset: 80,
								message: '请填写投稿内容'
							});
							return
				}
				if(this.form.image == []){
					this.$message({
								type: 'error',
								offset: 80,
								message: '请填写封面图'
							});
							return
				}
					if(this.form.images == []){
					this.$message({
								type: 'error',
								offset: 80,
								message: '请填写投稿图片'
							});
							return
				}
				this.$confirm('确定提交吗?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					  this.$https
			.get(`/api/pc/contribute?name=${this.form.name}&phone=${this.form.phone}&title=${this.form.title}&image=${this.form.image.join(',')}&images=${this.form.images.join(',')}&contribute=${this.form.contribute}`,).then(res => {
						if (res.code == 1) {
							this.$message({
								type: 'success',
								offset: 80,
								duration: 4000,
								message: '提交成功!'
							});
							this.form.name = '';
							this.form.phone = '';
							this.form.title = '';
							this.form.contribute = '';
							this.form.image=[];
							this.form.images=[];
							location. reload()
						} else {
							this.$message({
								type: 'error',
								offset: 80,
								message: res.msg
							});
						}
					})
				})
			},
			//重置
			chongzhi() {
				this.form.name = '';
				this.form.phone = '';
				this.form.dutuies = '';
			},
			//获取信息
			getinfo() {
				this.$api.home.lxwm().then(res => {
					if (res.code == 1) {
						this.info = res.data;
					}
				})
			},
			changenav(path) {
				this.path = path;
				this.$router.push({
					path: path
				})
				sessionStorage.setItem('pagepath', path)
			}
		}
	}
</script>

<style scoped>
	.formbox {
		width: 1040px;
		margin: 54px auto;
		position: relative;
	}

	.formbox .title {
		font-size: 48px;
		color: #1E2832;
		text-align: center;
		margin-bottom: 85px;
	}

	.formbox .titles {
		font-size: 68px;
		font-family: Adobe Heiti Std;
		font-weight: normal;
		color: #1E2832;
		opacity: 0.1;
		position: absolute;
		top: 0;
		left: 90px;
	}

	.formbox .titless {
		font-size: 18px;
		color: #b9b9b9;
		margin-top: 30px;
	}

	.formbox input {
		width: 512px;
		height: 62px;
		font-size: 18px;
		border: 2px solid #e5e5e5;
		border-radius: 4px;
		outline: none;
		box-sizing: border-box;
		padding: 0 33px;
	}

	.formbox textarea {
		width: 1040px;
		height: 163px;
		font-size: 18px;
		border: 2px solid #e5e5e5;
		border-radius: 4px;
		outline: none;
		box-sizing: border-box;
		padding: 18px 33px;
		margin-top: 19px;
	}

	.formbox .btnbox {
		width: 451px;
		margin: 0 auto;
		margin-top: 70px;
	}

	.formbox .btnbox .btn1 {
		width: 175px;
		height: 50px;
		background: #0049C2;
		font-size: 18px;
		color: #ffffff;
		border-radius: 50px;
	}

	.formbox .btnbox .btn2 {
		width: 175px;
		height: 50px;
		border: #1E2832 solid 2px;
		font-size: 18px;
		color: #131A21;
		border-radius: 50px;
	}
</style>